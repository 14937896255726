export const REGULAR_USER_LOCAL_KEY = 'regular_user_auth';

export function uint8ArrayToBase64 (uint8Array: any) {
  return btoa(String.fromCharCode.apply(null, uint8Array));
}

export function base64ToUint8Array(base64String: string) {
  const binaryString = atob(base64String);
  const uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
  }
  return uint8Array;
}